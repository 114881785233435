<template>
   <div class="is-flex is-flex-direction-column is-align-items-center is-justify-content-space-evenly is-full-height is-overflow-x-scroll">
      
      <img src="@/assets/done.svg" class="success-img" />

      <div>
        <h3>
          <b>{{itemIds.length}} item(s) <br/> successfully {{typeOfAction}} to database</b>
        </h3>
      </div>

      <div class="is-flex is-flex-direction-column is-half-width">
        <button 
          v-if="!addToBasketIsDone"
          :class="{'button height-fits-content has-background-green-4 m-1 mb-2':true,'isLoading':addToBasketIsLoading}" 
          @click="handleAddToBasketAndAddSimilarItems">
          ADD TO BASKET &<br> ADD SIMILIAR ITEMS
        </button>
        <button 
          v-if="!addToBasketIsDone"
          :class="{'button height-fits-content has-background-yellow-4 m-1 mb-2':true,'isLoading':addToBasketIsLoading}" 
          @click="handleAddToBasketAndAddMoreItems">
          ADD TO BASKET &<br> ADD MORE ITEMS
        </button>
        
        <button 
          class="button has-background-green-5 m-1" 
          @click="$router.push('/AddDing?similarItem=true')">
          ADD SIMILAR ITEMS
        </button>

        <button 
          class="button has-background-yellow-5 m-1" 
          @click="$router.push('/AddDing')">
          ADD MORE ITEMS
        </button>

        <button 
        v-if="itemIds.length == 1" class="button has-background-yellow-5 m-1" 
          @click="$router.push('/AddDing?insideof='+itemIds[0])">
          ADD ITEM INSIDE
        </button>

        <button v-if="itemIds.length == 1" :class="{'button has-background-grey-lighter m-1':true}"
          @click="handleEditItem" >
          EDIT ITEM
        </button>

        <button
          v-if="!addToBasketIsDone"
          :class="{'button has-background-grey-lighter m-1':true,'isLoading':addToBasketIsLoading}"
          :disabled="addToBasketIsLoading"
          @click="handleAddToBasket">
          ADD TO BASKET
        </button>

    
        <div class="is-menubar-height"></div>

      </div>
      


   </div>
</template>

<script>

import { onMounted, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRoute, useRouter } from 'vue-router';


export default {
  name: "Success",
  props: [],
  emits: [],
  components: { },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    
    const itemIds = ref([]);
    const typeOfAction = ref(null);

    const addToBasketIsLoading = ref(false);
    const addToBasketIsDone = ref(false);

    onMounted(()=>{
      store.dispatch("showTabBar");

      getItemReferencesFromQuery(route.query);
      console.log(store.state.ui.config.items2basket);
      if (store.state.ui.config.items2basket){
        handleAddToBasket();
      }
    })

    const handleEditItem = async () =>{
      console.log("editItem",itemIds.value[0]);
      router.push('/EditDing?id='+itemIds.value[0])
    }

    const handleAddToBasket = async () => {
      addToBasketIsLoading.value = true;
      let items = [];
      for (let id of itemIds.value){
        let item = await store.dispatch('getItem',id);
        if (!item) alert("something went wrong");
        items.push(item)
      }
      console.log("items to add to basket",items);
      await store.dispatch('add2Cart',{items})
      addToBasketIsLoading.value = false;
      addToBasketIsDone.value = true;
    }

    const getItemReferencesFromQuery = async (query)=>{
      if (query.addedSingle){
        itemIds.value = [query.addedSingle];
        typeOfAction.value = "added"
      }
      if(query.editedSingle){
        itemIds.value = [query.editedSingle];
        typeOfAction.value = "updated"
      }
    }

    const handleAddToBasketAndAddMoreItems = async ()=>{
      await handleAddToBasket();
      router.push("/AddDing");
    }

       const handleAddToBasketAndAddSimilarItems = async ()=>{
      await handleAddToBasket();
      router.push("/AddDing?similarItem=true");
    }

    return {handleEditItem, handleAddToBasket,itemIds,typeOfAction,addToBasketIsLoading,addToBasketIsDone,handleAddToBasketAndAddMoreItems,handleAddToBasketAndAddSimilarItems};
  },
};
</script>

